import Table from "react-bootstrap/Table";

export default function CompanySummary({ richProjects }) {
  const remainingTotal = richProjects.reduce(
    (sum, p) => sum + Number(p.remainingTotal),
    0,
  );
  const avgRate =
    richProjects.reduce((sum, r) => sum + Number(r.avgRate), 0) /
    richProjects.length;
  const hoursLeft = richProjects.reduce(
    (sum, p) => sum + Number(p.hoursLeft),
    0,
  );

  return richProjects.length > 0 ? (
    <div className="card my-2">
      <div className="card-body">
        <h5 className="card-title">Company-wide</h5>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>Active projects</th>
              <th className="text-right">Remaining billables</th>
              <th className="text-right">Avg rate for active projects</th>
              <th className="text-right">Hours available</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                DataMade is working on {richProjects.length} active projects
              </td>
              <td className="text-right">{moneyfmt.format(remainingTotal)}</td>
              <td className="text-right">{moneyfmt.format(avgRate)}</td>
              <td className="text-right">
                {hoursLeft.toLocaleString("en-US")}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  ) : (
    <></>
  );
}

const moneyfmt = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});
